import { IDidomiObject } from "@didomi/react";
import TagManager from "react-gtm-module";

export function initialize(globalDataLayerArgs: () => { [key: string]: any }) {
  let isReady = false;
  const initialize = (gtmId: string) => {
    if (isReady) {
      return;
    }

    isReady = true;
    return TagManager.initialize({ gtmId });
  };

  const isEnabled = () =>
    (
      window as { Didomi?: IDidomiObject }
    ).Didomi?.getUserConsentStatusForAll().purposes.enabled.includes("cookies");

  const tagEvent = (dataLayer: {
    eventAction: string;
    eventCategory: string;
    eventLabel: string;
  }) => {
    if (!isEnabled()) {
      return;
    }

    return TagManager.dataLayer({
      dataLayer: { ...dataLayer, event: "uaevent" },
    });
  };

  let activePage = "";
  const tagPage = (dataLayer: { category_page: string; nom_page: string }) => {
    if (!isEnabled() || dataLayer.nom_page === activePage) {
      return;
    }

    activePage = dataLayer.nom_page;
    return TagManager.dataLayer({
      dataLayer: {
        ...globalDataLayerArgs(),
        ...dataLayer,
        event: "virtualpage",
      },
    });
  };

  return { initialize, isEnabled, tagEvent, tagPage };
}
