import { initialize } from "@epc-front/shared/services/Gtm";

import { i18next } from "../config/i18next";
import { envType } from "../constants/envType";
import store from "../store";

export const Gtm = initialize(() => ({
  env_site: "web",
  env_working: envType === "prod" ? "prod" : "staging",
  langue: i18next.language,
  marche: "ancien",
  metier: "louer",
  univers: "b2c",
  univers_nexity: "mynexity Grands Comptes",
  user_id: "",
  user_interne: "",
  user_logged: store.getState().auth.currentUser ? "oui" : "non",
  user_profile: "bailleur",
}));
