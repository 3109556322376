import { privateApi } from ".";
import { Document } from "../../models/Document";
import { Patrimony } from "../../models/Patrimony";
import { SearchCriteria } from "../../models/SearchCriteria";
import { User } from "../../models/User";
import { setCurrentUser } from "../../store/auth";

const baseUrl = "/api/user";
export const currentUserApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    acceptTermsOfService: builder.mutation<void, void>({
      query: () => ({
        url: `${baseUrl}/accept-terms-of-service`,
        method: "POST",
      }),
      onQueryStarted: (_arg, queryLifecycleApi) => {
        queryLifecycleApi.dispatch(
          setCurrentUser({
            ...(queryLifecycleApi.getState() as any).auth.currentUser,
            hasAcceptedTermsOfService: true,
          })
        );
      },
    }),
    getCurrentUserInfo: builder.query<User, void>({
      query: () => ({ url: `${baseUrl}/info`, method: "GET" }),
      onQueryStarted: async (_arg, queryLifecycleApi) => {
        const response = await queryLifecycleApi.queryFulfilled;
        queryLifecycleApi.dispatch(setCurrentUser(response.data));
      },
    }),
    getCurrentUserLatestDocuments: builder.query<Document[], void>({
      query: () => ({ url: `${baseUrl}/latest-documents`, method: "GET" }),
    }),
    getCurrentUserPatrimony: builder.query<Patrimony, void>({
      query: () => ({ url: `${baseUrl}/patrimony`, method: "GET" }),
    }),
    getCurrentUserSearchCriteria: builder.query<SearchCriteria, void>({
      query: () => ({ url: `${baseUrl}/search-criteria`, method: "GET" }),
    }),
    updateCurrentUserInfo: builder.mutation<
      void,
      Pick<User, "firstName" | "lastName" | "phoneNumber">
    >({
      query: (arg) => ({ url: `${baseUrl}/profile`, method: "PUT", data: arg }),
      onQueryStarted: (arg, queryLifecycleApi) => {
        queryLifecycleApi.dispatch(
          setCurrentUser({
            ...(queryLifecycleApi.getState() as any).auth.currentUser,
            ...arg,
          })
        );
      },
    }),
  }),
});

export const {
  useAcceptTermsOfServiceMutation,
  useGetCurrentUserInfoQuery,
  useGetCurrentUserLatestDocumentsQuery,
  useGetCurrentUserPatrimonyQuery,
  useGetCurrentUserSearchCriteriaQuery,
  useUpdateCurrentUserInfoMutation,
} = currentUserApi;
