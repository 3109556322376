import { ErrorBoundary as SharedErrorBoundary } from "@epc-front/shared/components/ErrorBoundary";

import { CatchErrorPage } from "./pages/CatchErrorPage";

type RootErrorBoundaryProps = { children: React.ReactNode };

export const RootErrorBoundary = (props: RootErrorBoundaryProps) => {
  const { children } = props;
  return (
    <SharedErrorBoundary
      render={(state) => (state.hasError ? <CatchErrorPage /> : children)}
    />
  );
};
